<template>
  <div class="chat-box">
    <div class="chat-header">
      <h1>胡说八道GPT</h1>
    </div>
    <div class="chat-history" ref="chatHistory">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="['message', message.type]"
      >
        <div class="message-content">
          <div class="avatar">
            <img :src="message.type === 'user' ? userAvatar : aiAvatar" alt="avatar" />
          </div>
          <div :class="['text', message.type]">{{ message.content }}</div>
        </div>
      </div>
    </div>
    <div class="chat-input">
      <input
        v-model="userInput"
        @keyup.enter="sendMessage"
        placeholder="请输入消息..."
      />
      <button @click="sendMessage">发送</button>
    </div>
    <div class="footer">
      <p>备案号：<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2024107892号</a></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";

export default {
  name: "ChatBox",
  setup() {
    const userInput = ref("");
    const messages = ref([]);
    const chatHistory = ref(null);

    const apiKey = "sk-vnem98sp21e4k4ko6he0mfk3ebordp5di53j3sr20pvlv4a4";
    const apiUrl = "https://api.aihao123.cn/luomacode-api/open-api/v1/chat/completions";
    const model = "gpt-3.5-16K"; // 使用月之暗面的 8K 模型
    const userAvatar = "https://img2.baidu.com/it/u=3025064253,3406204751&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=800";
    const aiAvatar = "https://img2.baidu.com/it/u=292499683,2909038406&fm=253&fmt=auto&app=120&f=GIF?w=475&h=487";

    const sendMessage = async () => {
      if (!userInput.value.trim()) return;

      messages.value.push({ type: "user", content: userInput.value });
      userInput.value = "";

      try {
        const response = await axios.post(apiUrl, {
          messages: [{ role: "user", content: messages.value[messages.value.length - 1].content }],
          model: model,
        }, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${apiKey}`
          }
        });

        const aiResponse = response.data.choices[0].message.content;
        typeWriterEffect(aiResponse);
      } catch (error) {
        console.error("Error fetching AI response:", error);
        messages.value.push({ type: "ai", content: "抱歉，我无法获取回复。" });
      }
    };

    const typeWriterEffect = (text) => {
      let index = 0;
      const message = ref({ type: "ai", content: "" });
      messages.value.push(message.value);

      const interval = setInterval(() => {
        if (index < text.length) {
          message.value.content += text[index];
          index++;
        } else {
          clearInterval(interval);
        }
      }, 50);
    };

    const scrollToBottom = () => {
      onMounted(() => {
        chatHistory.value.scrollTop = chatHistory.value.scrollHeight;
      });
    };

    return {
      userInput,
      messages,
      chatHistory,
      sendMessage,
      typeWriterEffect,
      scrollToBottom,
      userAvatar,
      aiAvatar,
    };
  },
};
</script>

<style scoped>
.chat-box {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 16px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-header {
  text-align: center;
  margin-bottom: 20px;
}

.chat-header h1 {
  font-size: 24px;
  color: #333;
}

.chat-history {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

.message {
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
}

.message.user {
  flex-direction: row-reverse;
}

.message-content {
  display: flex;
  align-items: center;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.text {
  white-space: pre-wrap; /* 保留换行符和空格 */
  text-align: left; /* 设置文本左对齐 */
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  background-color: skyblue;
}

.text.user {
  background-color: lightgreen; /* 用户消息背景颜色 */
}

.text.ai {
  background-color: lightgray; /* AI 消息背景颜色 */
}

.chat-input {
  display: flex;
  justify-content: space-between;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.footer {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: #666;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>